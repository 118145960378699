<template>
  <div class="schedulePayment">
    <div class="go-back">
      <div @click="onClickLeft" style="flex: 1">
        <van-icon name="arrow-left" class="icon-left" />
        <span>按期付款</span>
      </div>
    </div>
    <div class="content">
      <div class="content-item">
        <div class="item-head">
          <div class="rests">
            <span>第{{ infoList.current_stage }}期</span>
          </div>
        </div>
        <div class="stages-price">
          <div>待付总额（元）</div>
          <div class="price">{{ infoList.repayment_amount }}</div>
        </div>
        <div class="stages-info">
          <div class="info-text">
            到期日：{{ infoList.repayment_date }}
            <span v-if="infoList.is_overdue">（已逾期）</span>
          </div>
          <!-- <div class="info-text">是否完结：<span v-if="infoList.is_settle">已完结</span><span v-else>未完结</span></div> -->
          <div class="info-text" v-if="infoList.is_delay_stage">
            是否延期：已延期
          </div>
          <!-- <div class="info-text">是否逾期：<span v-if="infoList.is_overdue">已逾期</span><span v-else>未逾期</span></div> -->
          <div class="info-text">
            分期金额：{{ infoList.repayment_principal }}
          </div>
          <div
            class="info-text"
            v-if="Number(infoList.repayment_interest_amount) > 0"
          >
            管理费：{{ infoList.repayment_interest_amount }}
          </div>
          <div class="info-text" v-if="Number(infoList.overdue_administrative_fee) > 0">
            逾期手续费：{{ infoList.overdue_administrative_fee }}
          </div>
        </div>
      </div>
      <van-radio-group v-model="payType">
        <div
          class="payment-method"
          v-if="bank_info"
          @click="getPayType('card')"
        >
          <div class="method-logo">
            <img :src="bank_info.bank_logo" alt="" />
          </div>
          <div class="method-info">
            <div class="method-name">{{ bank_info.bank_name }}</div>
            <div class="method-number">
              {{ bank_info.bank_card_no }}
            </div>
          </div>
          <!-- <div class="deduct">扣款银行卡</div> -->
          <van-radio
           
            name="card"
            icon-size="24px"
          ></van-radio>
        </div>
        <div v-else class="payment-method"
          @click="getPayType('card')"
        >
          <div class="method-logo">
            <img src="@/assets/img/card_pay.png" alt="" />
          </div>
          <div class="method-info">
            <div class="method-name">银行卡支付</div>
          </div>
          <van-radio
            name="card"
            icon-size="24px"
          ></van-radio>
        </div>
        <!-- v-if="isWeixin" -->
        <div
         
          class="payment-method"
          @click="getPayType('WeChat')"
        >
          <div class="method-logo">
            <img src="@/assets/img/WeChatPay.png" alt="" />
          </div>
          <div class="method-info">
            <div>微信支付</div>
          </div>
          <van-radio name="WeChat" icon-size="24px"></van-radio>
        </div>
        <!-- 支付宝支付 -->
        <div
          class="payment-method"
          @click="getPayType('aliPay')"
        >
          <div class="method-logo">
            <img src="@/assets/img/alipay.png" alt="" />
          </div>
          <div class="method-info">
            <div>支付宝支付</div>
          </div>
          <van-radio name="aliPay" icon-size="24px"></van-radio>
        </div>
      </van-radio-group>
      <div
        class="payment-button"
        v-if="infoList.is_settle"
        style="background: #708eb8"
      >
        已结清
      </div>
      <div
        class="payment-button"
        style="margin-top: 10px"
        v-else-if="Number(infoList.repayment_amount) > 0"
        @click="getPayment"
      >
        确认付款
      </div>
      <!-- <div class="payment-button" v-else style="background:#708EB8">确认付款</div> -->
    </div>
    <confirm
      :show.sync="showConfirm"
      title="确定付款?"
      @ok="onConfirmPay"
    ></confirm>
  </div>
</template>

<script>
import {
  billStageDetail,
  advanceRepayment,
  prePaymentByWechat,
  prePaymentByAliPay
} from "@/api/bill.js";
import { isWeixin } from "@/utils/index.js";
import Confirm from "@/components/confirm";
import { Toast } from "vant";
export default {
  components: {
    Confirm,
  },
  name: "schedulePayment",

  data() {
    return {
      payType: "card",
      infoList: {},
      bill_stage_id: "",
      isWeixin: isWeixin(),
      showConfirm: false,
      isPaying: false,
      bank_info: null,
      openId: "",
    };
  },

  mounted() {
    this.openId = localStorage.getItem("openId");
    this.bill_stage_id = this.$route.query.bill_stage_id;
    this.initData();
  },

  methods: {
    getPayType(type) {
      this.payType = type;
    },
    async initData() {
      const parme = {
        billStageId: this.bill_stage_id,
      };
      const { data, code } = await billStageDetail(parme);
      if (code == 200) {
        var overdue_administrative_fee = Number(data.overdue_late_fee) + Number(data.overdue_fine)
        this.infoList = {
          ...data,
          overdue_administrative_fee:overdue_administrative_fee.toFixed(2)
        };
        if (data.bank_info && data.bank_info.bank_card_no) {
          this.bank_info = data.bank_info;
        } else {
          if (this.isWeixin) {
            this.payType = "WeChat";
          }
          //  else {
          //   this.payType = "aliPay";
          // }
        }
      }
    },
    async getPayment() {
      const that = this;
      if (this.payType == "card") {
        if (!this.bank_info) {
          // Toast("请先绑定银行卡");
          this.cardBindPay()
        } else {
          if (this.isPaying) return;
          this.showConfirm = true;
        }
      } else if (this.payType == "WeChat") {
        // console.log("微信支付");
        const parmes = {
          billId: this.infoList.bill_id,
          billStageId: this.infoList.bill_stage_id,
          advanceType: 1,
          openId: this.openId,
        };
        const { code, data } = await prePaymentByWechat(parmes);
        if (code == 200) {
          that.wxPay(data.prepay);
        }
      } else if(this.payType == "aliPay"){
        const parmes = {
          billId: this.infoList.bill_id,
          billStageId: this.infoList.bill_stage_id,
          advanceType: 1
        };
        const { code, data } = await prePaymentByAliPay(parmes);
        if (code == 200) {
          that.aliPay(data.prepay);
        }
      }
    },
    // 支付宝支付
    aliPay(prepay){
      this.$router.push({
        path: "/AlipayPayment",
        query: {
          alipay_url: prepay,
        },
      });
    },
    // 微信支付
    wxPay(prepay) {
      var vm = this;
      if (typeof WeixinJSBridge == "undefined") {
        if (document.addEventListener) {
          document.addEventListener(
            "WeixinJSBridgeReady",
            vm.onBridgeReady(prepay),
            false
          );
        } else if (document.attachEvent) {
          document.attachEvent("WeixinJSBridgeReady", vm.onBridgeReady(prepay));
          document.attachEvent(
            "onWeixinJSBridgeReady",
            vm.onBridgeReady(prepay)
          );
        }
      } else {
        vm.onBridgeReady(prepay);
      }
    },
    cardBindPay() {
      //去绑定银行卡
      this.$router.push({
        path:'/changeBankCard',
        query: {
          type: 'cardPay'
        }
      })
    },
    onBridgeReady(prepay) {
      const that = this;
      window.WeixinJSBridge.invoke(
        "getBrandWCPayRequest",
        {
          appId: prepay.appId,
          timeStamp: prepay.timeStamp,
          nonceStr: prepay.nonceStr,
          package: prepay.package,
          signType: prepay.signType,
          paySign: prepay.paySign,
        },
        function (res) {
          // console.log('weixinzhifu',res)
          this.isPaying = false;
          if (res.err_msg == "get_brand_wcpay_request:ok") {
            this.isPaying = false;
            Toast.clear();
            Toast.success("支付成功");
            that.initData();
          } else {
            Toast("支付失败");
            this.isPaying = false;
          }
        }
      );
    },
    async onConfirmPay() {
      this.isPaying = true;
      Toast.loading({
        duration: 0,
        message: "支付中",
      });
      const parmes = {
        billId: this.infoList.bill_id,
        billStageId: this.infoList.bill_stage_id,
        advanceType: 1,
      };
      const { code } = await advanceRepayment(parmes);
      this.isPaying = false;
      if (code == 200) {
        Toast.clear();
        Toast.success("还款成功");
        setTimeout(() => {
          this.$router.go(-1);
        }, 1500);
        this.isPaying = false;
      } else {
        this.isPaying = false;
      }
    },

    onClickLeft() {
      let returnPath = localStorage.getItem("returnOrderPath");
      if (returnPath == "/payment") {
        this.$router.push("/payment");
      } else if (returnPath == "/paymentSchedule") {
        this.$router.push({
          path: "/paymentSchedule",
          query: {
            bill_id: this.infoList.bill_id,
          },
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.schedulePayment {
  height: 100%;
  width: 100%;
  font-size: 30px;
  background: #f5f5f5;
  color: #333;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  .go-back {
    display: flex;
    align-items: center;
    padding-left: 20px;
    font-size: 30px;
    width: 100%;
    background: #fff;
    border-top: 1px solid #e7e7e7;
    border-bottom: 1px solid #e7e7e7;
    height: 100px;
  }
  .content {
    flex: 1;
    overflow: auto;
    padding: 30px;

    .content-item {
      padding: 30px 0px;
      background: #fff;
      box-shadow: 0px 0px 10px 0px #f5f5f5;
      border-radius: 14px;
      .item-head {
        display: flex;
        align-items: center;
        .rests {
          display: flex;
          align-items: center;
          border-radius: 0px 50px 50px 0px;
          background: #1677ff;
          padding: 10px 37px;
          color: #fff;
          font-size: 24px;
        }
      }
      .stages-price {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 30px;
        color: #333;
        .price {
          font-size: 97px;
          font-weight: 600;
          margin: 20px 0px;
        }
      }
      .stages-info {
        margin: 0px 30px;
        border-top: 1px solid #e6e6e6;
        .info-text {
          font-size: 30px;
          padding: 20px 10px 0px 10px;
        }
      }
    }
    .payment-method {
      margin: 30px 0px;
      padding: 30px;
      background: #fff;
      box-shadow: 0px 0px 10px 0px #f5f5f5;
      border-radius: 14px;
      display: flex;
      .method-logo {
        height: 80px;
        width: 80px;
        border-radius: 50%;
        background: #fff;
        margin-right: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          height: 70px;
          width: 70px;
          border-radius: 50%;
        }
      }
      .method-info {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .method-number {
          margin-top: 10px;
        }
      }
      .deduct {
        font-size: 24px;
        color: #999;
      }
    }
    .payment-button {
      // margin: 30px;
      padding: 27px 200px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      background: #1677ff;
      box-shadow: 0px 7px 13px 0px rgba(22, 119, 255, 0.32);
      border-radius: 50px;
    }
  }
}
</style>